import type { ItemModelType } from 'o365-dataobject';
import { DataObject } from 'o365-dataobject';
import { API } from 'o365-modules';
import { dateUtils } from 'o365-utils';
import { reactive } from 'vue';

declare module "o365-dataobject" {
    interface DataObject<T> {
        bulkUpdate: BulkUpdate<T>;
    }
}

Object.defineProperty(DataObject.prototype, 'bulkUpdate', {
    get() {
        if (this._bulkUpdate == null) {
            this._bulkUpdate = new BulkUpdate(this);
        };
        return this._bulkUpdate;
    },
});



export default class BulkUpdate<T extends object = any> {
    private _dataObject: DataObject<T>;
    private _cancelBulkUpdate: boolean;

    get cancelBulkUpdate(): boolean {
        return this._cancelBulkUpdate;
    }
    set cancelBulkUpdate(newValue: boolean) {
        this._cancelBulkUpdate = newValue;
    }

    private _onBeforeBulkUpdate?: (options: {
        bulkItem: T,
        selectedItems: ItemModelType<T>[],
        bulkFields: string[],
    }, requestData: IBulkUpdateRequest<T>) => Promise<true | IBulkUpdateRequest<T>>;

    constructor(dataObject: DataObject<T>) {
        this._dataObject = dataObject;
        this._cancelBulkUpdate = false;
    }

    /** Before bulk update custom handler. Return true if should skip the default handler  */
    setOnBeforeBulkUpdate(handler: (options: {
        bulkItem: T,
        selectedItems: ItemModelType<T>[],
        bulkFields: string[]
    }, requestData: IBulkUpdateRequest<T>) => Promise<true | IBulkUpdateRequest<T>>) {
        this._onBeforeBulkUpdate = handler;
    }

    async doBulkUpdate(options: {
        bulkItem: T,
        selectedItems: ItemModelType<T>[],
        bulkFields: string[]
    }) {
        if (options?.bulkFields) {
            for (const field of options.bulkFields) {
                let value = options.bulkItem?.[field as keyof T];
                if (this._checkIfDate(field)) {
                    if (value) {
                        options.bulkItem[field as keyof T] = dateUtils.dropTimezoneInfo(value);
                    }
                } else if (this._checkIfDateTimeOffset(field)) {
                    if (value instanceof Date) {
                        const offset = dateUtils.getTimezoneOffset(value);
                        options.bulkItem[field as keyof T] = `${dateUtils.dropTimezoneInfo(value)}${offset}` as any;
                    }
                }
            }
        } 

        this._dataObject.emit('BeforeBulkUpdate', options);
        await this._dataObject.eventHandler.emitAsync('BeforeBulkUpdateAsync', options);
        if(this.cancelBulkUpdate || options.cancelEvent === true){
            return{};
        }
        if (this._dataObject.hasPropertiesData && options.bulkFields?.some((field: string) => field.startsWith('Property.'))) {
            return this._dataObject.propertiesData.bulkUpdate({
                bulkItem: options.bulkItem,
                bulkFields: options.bulkFields,
                selectedItems: options.selectedItems.value,
            });
        }
        let valuesObject = {};
        Object.keys(options['bulkItem']).forEach(function (item) {
            valuesObject[item] = options['bulkItem'][item];
        });

        let uniqueColumnID = options['uniqueColumnID'] || this._dataObject.fields.uniqueField;
        let uniqueColumnValues = options['selectedItems'].value.map(function (item) {
            return options['selectedItems'].value[0].appId ? item[uniqueColumnID] : item;
        }).toString();

        let requestData = {
            "viewName": this._dataObject.viewName,
            "uniqueTable": this._dataObject.uniqueTable,
            "values": valuesObject,
            "operation": "update",
            "bulk": true,
            "key": uniqueColumnID,
            "ids": uniqueColumnValues
        };

        if (this._onBeforeBulkUpdate != null) {
            const updated = await this._onBeforeBulkUpdate(options, requestData);
            if (updated === true) { return {}; }
            if(updated != undefined)
                requestData = updated;
        }

        try {
            const bulkProgress = reactive(new BulkUpdateProgress({
                dataObjectId: this._dataObject.id,
                bulkSize: this._dataObject.recordSource.bulkSize ?? 500,
                keys: uniqueColumnValues.split(','),
                requestData: requestData
            }));
            if (options.getProgress) {
                options.getProgress(bulkProgress);
            }

            // const response = await API.request({
            //     requestInfo: '/nt/api/data',
            //     method: 'POST',
            //     headers: new Headers({
            //         'Accept': 'application/json',
            //         'Content-Type': 'application/json'
            //     }),
            //     body: JSON.stringify(requestData),
            //     responseBodyHandler: API.ResponseHandler.Raw
            // });

            // const jsonResponse = await response.json();
            const  jsonResponse = await bulkProgress.process();

            // Refresh all updated items
            const key = requestData.key;
            const ids = uniqueColumnValues;
            const promises = this._dataObject.storage.data.filter((item: any) => ids.includes(item[key])).map(item => {
                return this._dataObject.refreshRow(item.index);
            });
            if (this._dataObject.batchDataEnabled) {
                this._dataObject.batchData.data.filter((item: any) => ids.includes(item[key])).forEach(item => promises.push(this._dataObject.refreshRow(item.index)));
            }
            this._dataObject.state.changedDate = new Date();
            await Promise.all(promises);

            this._dataObject.emit('AfterBulkUpdate', options, requestData, jsonResponse);
            return jsonResponse;
        } catch (ex) {
            return { error: ex?.message };
        }
    }

    protected _checkIfDate(pKey: string) {
        const field = this._dataObject.fields[pKey];
        return field && (field.dataType == 'date' || field.dataType == 'datetime' || field.type == 'date');
    }

    protected _checkIfDateTimeOffset(pKey: string) {
        const field = this._dataObject.fields[pKey];
        return field?.dataType === 'datetimeoffset';
    }

}

interface IBulkUpdateRequest<T extends object = any> {
    'viewName': string,
    'uniqueTable': string,
    'values': T,
    'operation': 'update',
    'bulk': true,
    'key': string,
    'ids': string,
};

export class BulkUpdateProgress {
    totalRows = 0;
    updatedRows = 0;
    done = false;
    error?: string;

    private _keys: (string | number)[];
    private _dataObjectId: string;
    private _bulkSize: number;
    private _requestData: any;
    private _responses: any[] = [];

    constructor(pOptions: {
        dataObjectId: string,
        keys: (string | number)[],
        requestData: any,
        bulkSize: number
    }) {
        this._dataObjectId = pOptions.dataObjectId;
        this._keys = pOptions.keys;
        this._requestData = pOptions.requestData;
        this._bulkSize = pOptions.bulkSize;
        this.totalRows = this._keys.length;
    }

    async process(): Promise<{ success: number }> {
        if (this.done) { return { success: this.updatedRows }; }

        const keys = this._keys.splice(0, this._bulkSize);
        try {
            const requestData = this._requestData;
            requestData.ids = keys.join(',');
            const response = await API.request({
                requestInfo: '/nt/api/data/' + this._dataObjectId + '_bulkUpdate',
                method: 'POST',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify(requestData),
                responseBodyHandler: API.ResponseHandler.Raw
            });
            const jsonResponse = await response.json();
            if ('error' in jsonResponse) {
                throw jsonResponse.error;
            }
            this.updatedRows += keys.length;
            this._responses.push(jsonResponse);
            if (this._keys.length) {
                return this.process();
            }
            this.done = true;
            return { success: this.updatedRows }
        } catch (ex: any) {
            this.error = ex?.message ?? ex;
            this.done = true;
            return Promise.reject(ex);
        }
    }
}